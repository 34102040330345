// @ts-ignore
import type { DocumentReference, GeoPoint } from '@angular/fire/firestore';
import { ICustomer } from './Customer';
import { IUser } from './User';
// @ts-ignore
import type { Timestamp } from 'firebase/firestore';
import { IVenue } from './Venue';

export interface ICard {
  id?: string;
  created: Timestamp;
  provisionedBy: DocumentReference<IUser>;
  updated: Timestamp;
  status: CardStatus;
  claimed?: Timestamp;
  /**
   * For storing Stripe related information.
   */
  stripe?: {
    customerId: string;
  };
  /**
   * Customer document reference.
   */
  customerRef?: DocumentReference<ICustomer>;
  /**
   * User document reference.
   */
  userRef?: DocumentReference<IUser>;
  /**
   * User ID. For Security rules.
   */
  uid?: string;
  /**
   * Preregistered contact information of the Customer. For Provisioned Card only. Should be deleted after.
   */
  customer?: {
    name: string | null;
    phone: string | null;
    email: string | null;
  };
  /**
   * Where the card was sold at. Venue document reference.
   */
  venueRef: DocumentReference<IVenue> | null;
  /**
   * Where the card was sold at. GeoPoint.
   */
  location: GeoPoint | null;
}

export enum CardStatus {
  /**
   * Card has been provisioned and is ready to be claimed by a customer and assigned to a User account.
   */
  'provisioned' = 'provisioned',
  /**
   * Card has been claimed by a specific Customer and User.
   */
  'claimed' = 'claimed',
  /**
   * Card has been deleted and should not be visible in the system.
   */
  'deleted' = 'deleted',
  /**
   * Card has expired. Cannot be claimed anymore.
   */
  'expired' = 'expired',
}

/**
 * Match string if it conforms the Card ID format.
 */
export const cardIdMatcher = (path: string): RegExpMatchArray | null => {
  return path.match(/^[A-Z1-9]{5}$/);
};

/**
 * Match URL string if it conforms the Card ID format. Checks the end of the path, ignores the domain.
 *
 * @return First array item in case of match is Card ID.
 */
export const cardIdUrlMatcher = (path: string): RegExpMatchArray | null => {
  return path.match(/\/([A-Z1-9]{5})$/);
};
